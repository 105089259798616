import React from "react";
import { Link } from "gatsby"


class ProjectTile extends React.Component {
	render() {
		return (
			<li className="projectTile" data-project-id={this.props.id}>
				<Link to={this.props.projectURL}>
					<img 
						src={ `/src/images${this.props.projectURL}/${this.props.imgSrc}` }
						alt={this.props.imgAlt}
						className="projectTile__image"
					/>
				</Link>
				
				<p className="projectTile__job">{this.props.projectJob}</p>
				<Link to={this.props.projectURL} className="projectTile__link">
					{this.props.projectBrand}
				</Link>
			</li>
		)
	}
}

export default ProjectTile;
