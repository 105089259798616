import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts/layout"
import ProjectTilesContainer from "../components/ProjectTilesContainer";
import ProjectTile from "../components/ProjectTile";

export default ({ data }) => {
  return (
	<Layout>
		<h1 className="sr-only">Work</h1>
		
		<ProjectTilesContainer>
			{data.indexJson.projects.map((tile, index) => (
				<ProjectTile key={index}
					id={tile.id}
					imgSrc={tile.imgSrc}
					imgAlt={tile.imgAlt}
					projectURL={ "/projects/" + tile.projectURL }
					projectBrand={tile.projectBrand}
					projectJob={tile.projectJob}
				/>
			))}
		</ProjectTilesContainer>
	</Layout>
	)
}

export const query = graphql`query {
	indexJson {
		projects {
			id,
			imgSrc,
			imgAlt,
			projectURL,
			projectBrand,
			projectJob
		}
	  }
	}
`
